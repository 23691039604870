
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { Actions } from "@/store/enums/StoreEnums";
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
export default defineComponent({
  setup() {
    const isLoading = ref(false);
    const store = useStore();

    async function init() {
      isLoading.value = true;
      //await store.dispatch("RiderModule/" + Actions.FETCH_RIDER_LIST);
      await store.dispatch("BillModule/" + Actions.FETCH_BILL_LIST);
      await store.dispatch("BillModule/" + Actions.FETCH_COUNT_BILL);
      isLoading.value = false;
    }

    onMounted(() => {
      setCurrentPageTitle("Invoice Management");
      init();
    });

    return { isLoading };
  },
});
